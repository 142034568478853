import { useMemo } from "react";

import { useGetVideoReviewComments } from "@ll-web/features/projectComments/async/useProjectCommentsQueries";
import { useFilterCommentThreads } from "@ll-web/features/projectComments/hooks/useFilterCommentThreads";

export function useGetThreadIndex({
  threadId,
  projectId,
  videoId,
}: {
  threadId: string;
  projectId: string;
  videoId: string;
}) {
  // this query should already be cached
  const commentsQuery = useGetVideoReviewComments({
    projectId,
    videoId,
  });

  const threads = useFilterCommentThreads({
    comments: commentsQuery.data,
  });

  const index = useMemo(
    () =>
      [...(threads ?? [])]
        .reverse()
        .findIndex((thread) => thread.id === threadId),
    [threads, threadId],
  );

  return { index };
}
