import * as yup from "yup";

export const notifyForReviewSchema = yup
  .object()
  .shape({
    title: yup.string().required(),
    message: yup.string().required(),
    people: yup.array().required().of(yup.string().required()).min(1),
  })
  .required();

export type NotifyForReviewFormValues = yup.InferType<
  typeof notifyForReviewSchema
>;
