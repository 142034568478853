import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  setDoc,
  where,
} from "firebase/firestore";

import { dataWithIdConverterFactory } from "@ll-web/core/firebase/converters";
import { firestore } from "@ll-web/core/firebase/firebaseService";
import { FirestoreCollections } from "@ll-web/core/firebase/types";
import { ProjectCommentSourceEnum } from "@ll-web/features/projectComments/enums";
import {
  type AddCommentArgs,
  type GetVideoReviewCommentsParams,
  type ProjectAndOutputSubcollectionsParams,
  type ProjectComment,
  type ProjectIdAndCommentIdParams,
  type ProjectIdAndCommentIdsParams,
  type ProjectIdAndCommentThreadIdParams,
  type UpdateCommentArgs,
  type WizardCacheInvalidationParams,
} from "@ll-web/features/projectComments/types";
import { ProjectSubCollections } from "@ll-web/features/projects/enums";

class ProjectCommentsService {
  public async addComment({
    projectId,
    outputSubcollection,
    ...comment
  }: AddCommentArgs): Promise<ProjectComment> {
    const collectionRef = collection(
      firestore,
      FirestoreCollections.Projects,
      projectId,
      ProjectSubCollections.Comments,
    ).withConverter(
      dataWithIdConverterFactory<Omit<ProjectComment, "id" | "threadId">>(),
    );

    const docRef = await addDoc(collectionRef, {
      ...comment,
      createdAt: new Date(),
    });

    const id = docRef.id;

    if (!comment.threadId) {
      // threadId of initial (thread founding) comment is the same as comment id
      await this.updateComment({
        projectId,
        id,
        threadId: id,
        userId: comment.userId,
        outputSubcollection,
      });
    }

    return (await this.getCommentById({ projectId, id }))!;
  }

  async updateComment({
    projectId,
    id,
    outputSubcollection: _,
    ...comment
  }: UpdateCommentArgs) {
    const docRef = doc(
      firestore,
      FirestoreCollections.Projects,
      projectId,
      ProjectSubCollections.Comments,
      id,
    ).withConverter(dataWithIdConverterFactory<ProjectComment>());

    await setDoc(
      docRef,
      {
        createdAt: new Date(),
        ...comment,
      },
      { merge: true },
    );

    return (await this.getCommentById({ projectId, id }))!;
  }

  public async deleteComment({
    projectId,
    id,
  }: ProjectIdAndCommentIdsParams &
    WizardCacheInvalidationParams): Promise<void> {
    const docRef = doc(
      firestore,
      FirestoreCollections.Projects,
      projectId,
      ProjectSubCollections.Comments,
      id,
    ).withConverter(dataWithIdConverterFactory<ProjectComment>());

    // we are not actually removing the comment so its contents can be previewed in case it is requested
    // it can be requested if the user in project wizard delets the comment but then discards the edit changes, in which case the removal actually gets reverted
    await setDoc(docRef, { isDeleted: true }, { merge: true });
  }

  public async resolveComment({
    projectId,
    id,
  }: ProjectIdAndCommentIdsParams &
    WizardCacheInvalidationParams): Promise<void> {
    const docRef = doc(
      firestore,
      FirestoreCollections.Projects,
      projectId,
      ProjectSubCollections.Comments,
      id,
    ).withConverter(dataWithIdConverterFactory<ProjectComment>());

    await setDoc(docRef, { isResolved: true }, { merge: true });
  }

  public async reopenComment({
    projectId,
    id,
  }: ProjectIdAndCommentIdsParams &
    WizardCacheInvalidationParams): Promise<void> {
    const docRef = doc(
      firestore,
      FirestoreCollections.Projects,
      projectId,
      ProjectSubCollections.Comments,
      id,
    ).withConverter(dataWithIdConverterFactory<ProjectComment>());

    await setDoc(docRef, { isResolved: false }, { merge: true });
  }

  public async getCommentById({
    projectId,
    id,
  }: ProjectIdAndCommentIdParams): Promise<ProjectComment | null> {
    const docRef = doc(
      firestore,
      FirestoreCollections.Projects,
      projectId,
      ProjectSubCollections.Comments,
      id,
    ).withConverter(dataWithIdConverterFactory<ProjectComment>());

    const data = (await getDoc(docRef)).data() as ProjectComment;
    if (!data) {
      return null;
    }

    return data;
  }

  public async getCommentsByThreadId({
    projectId,
    threadId,
  }: ProjectIdAndCommentThreadIdParams): Promise<ProjectComment[] | null> {
    const collectionRef = collection(
      firestore,
      FirestoreCollections.Projects,
      projectId,
      ProjectSubCollections.Comments,
    ).withConverter(dataWithIdConverterFactory<ProjectComment>());

    const result = await getDocs(
      query(
        collectionRef,
        where("threadId", "==", threadId),
        orderBy("createdAt", "asc"),
      ),
    );

    return result.docs.map((doc) => doc.data());
  }

  public async getCommentsByProjectIdAndOutputSubcollections({
    projectId,
    outputSubcollections,
  }: ProjectAndOutputSubcollectionsParams): Promise<ProjectComment[] | null> {
    const collectionRef = collection(
      firestore,
      FirestoreCollections.Projects,
      projectId,
      ProjectSubCollections.Comments,
    ).withConverter(dataWithIdConverterFactory<ProjectComment>());

    const result = await getDocs(
      query(
        collectionRef,
        where("target.source", "==", ProjectCommentSourceEnum.Wizard),
        where("target.outputSubcollection", "in", outputSubcollections),
        orderBy("createdAt", "desc"),
      ),
    );

    return result.docs.map((doc) => doc.data());
  }

  public async getVideoReviewComments({
    projectId,
    videoId,
  }: GetVideoReviewCommentsParams): Promise<ProjectComment[] | null> {
    const collectionRef = collection(
      firestore,
      FirestoreCollections.Projects,
      projectId,
      ProjectSubCollections.Comments,
    ).withConverter(dataWithIdConverterFactory<ProjectComment>());

    const result = await getDocs(
      query(
        collectionRef,
        where("target.source", "==", ProjectCommentSourceEnum.VideoReview),
        where("target.videoId", "==", videoId),
        orderBy("createdAt", "desc"),
      ),
    );

    return result.docs.map((doc) => doc.data());
  }
}

export const projectCommentsService = new ProjectCommentsService();
