import { ActivityType } from "@ll-web/core/analytics/events";

import { ReviewNotificationType } from "./types";

export const notifyReviewToastMessagesMap = (
  reviewNotificationType: ReviewNotificationType,
): {
  success?: string;
  error?: string;
  pending?: string;
} => {
  switch (reviewNotificationType) {
    case ReviewNotificationType.CreativeBriefInternal:
    case ReviewNotificationType.WizardGenerateExternal:
    case ReviewNotificationType.WizardGenerateInternal:
      return {
        pending: "Submitting Creative Brief...",
        success: "Creative Brief submitted!",
      };
    case ReviewNotificationType.CreativeDeckEditExternal:
    case ReviewNotificationType.CreativeDeckEditInternal:
      return {
        pending: "Submitting changes to Creative Deck...",
      };
  }
};

export const notifyReviewAnalyticsEventsMap: Record<
  ReviewNotificationType,
  { submitting: ActivityType; cancel: ActivityType } | undefined
> = {
  [ReviewNotificationType.CreativeBriefInternal]: {
    submitting: ActivityType.CreativeBriefInputClickedSubmitForReview,
    cancel: ActivityType.CreativeBriefInputCanceledSubmittingForReview,
  },
  [ReviewNotificationType.WizardGenerateInternal]: {
    submitting: ActivityType.CreativeBriefClickedSubmitForReview,
    cancel: ActivityType.CreativeBriefCanceledSubmittingForReview,
  },
  [ReviewNotificationType.WizardGenerateExternal]: {
    submitting: ActivityType.CreativeBriefClickedSubmitForReview,
    cancel: ActivityType.CreativeBriefCanceledSubmittingForReview,
  },
  [ReviewNotificationType.CreativeDeckEditInternal]: undefined,
  [ReviewNotificationType.CreativeDeckEditExternal]: undefined,
};

export const notifyReviewModalTitleMap: Record<ReviewNotificationType, string> =
  {
    [ReviewNotificationType.CreativeBriefInternal]:
      "Notify Producer for Review",
    [ReviewNotificationType.WizardGenerateInternal]: "Notify Client for Review",
    [ReviewNotificationType.WizardGenerateExternal]:
      "Notify Producer for Review",
    [ReviewNotificationType.CreativeDeckEditInternal]:
      "Notify Client for Review",
    [ReviewNotificationType.CreativeDeckEditExternal]:
      "Notify Producer for Review",
  };
