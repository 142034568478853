import { defined } from "@ll-web/utils/types/types";

import type { ReviewNotificationType } from "./types";

type MakeReviewNotificationKeyArgs = {
  projectId: string;
  reviewNotificationType: ReviewNotificationType;
  resourceId?: string;
};

export const makeReviewNotificationKey = ({
  projectId,
  reviewNotificationType,
  resourceId,
}: MakeReviewNotificationKeyArgs): string => {
  return ["project", projectId, reviewNotificationType, resourceId]
    .filter(defined)
    .join(":");
};
