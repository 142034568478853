import { type ReactNode } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { Button, DialogActions, Stack, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { SmallWhiteTextField } from "@ll-web/components/form/SmallWhiteTextField/SmallWhiteTextField";
import type { User } from "@ll-web/features/auth/types";
import { useActiveProject } from "@ll-web/features/projectWizard/hooks/useActiveProject";

import { notifyReviewToastMessagesMap } from "./consts";
import { getNotifyFormDefaultValues } from "./defaults";
import { MultiUserAutocomplete } from "./MultiUserAutocomplete";
import {
  notifyForReviewSchema,
  type NotifyForReviewFormValues,
} from "./notify-for-review.schema";
import type { ReviewNotificationType } from "./types";

type NotifyModalFormProps = {
  defaultReceivers: string[];
  defaultReceiversData: User[];
  notificationType: ReviewNotificationType;
  onSubmitForReview: (payload: NotifyForReviewFormValues) => Promise<void>;
  confirmationContent: ReactNode;
  submitButtonContent: string;
  onClose: () => void;
};

export const NotifyModalForm = ({
  defaultReceivers,
  defaultReceiversData,
  notificationType,
  onSubmitForReview,
  confirmationContent,
  submitButtonContent,
  onClose,
}: NotifyModalFormProps) => {
  const { activeProject } = useActiveProject();

  const { mutateAsync: doSubmit, isPending } = useMutation({
    mutationFn: onSubmitForReview,
    meta: {
      supressErrorToast: true,
    },
  });

  const methods = useForm({
    defaultValues: getNotifyFormDefaultValues({
      projectName: activeProject.title,
      notificationType,
      projectStyle: activeProject.style,
      defaultReceivers,
      defaultReceiversData,
      projectId: activeProject.id,
    }),
    resolver: yupResolver(notifyForReviewSchema),
    mode: "onBlur",
  });

  const handleSubmit = (payload: NotifyForReviewFormValues) => {
    return toast.promise(
      doSubmit(payload),
      notifyReviewToastMessagesMap(notificationType),
    );
  };

  const handleClose = () => {
    methods.reset();
    onClose();
  };

  return (
    <Stack component="form" onSubmit={methods.handleSubmit(handleSubmit)}>
      <FormProvider {...methods}>
        <Stack
          sx={{
            flexGrow: 1,
            flexDirection: "column",
            gap: 3,
          }}
        >
          <Typography>{confirmationContent}</Typography>
          <MultiUserAutocomplete
            label="To"
            defaultReceiversData={defaultReceiversData}
          />
          <Controller
            control={methods.control}
            name="title"
            render={({ field: { ref: _, ...field }, fieldState }) => (
              <SmallWhiteTextField
                {...field}
                error={fieldState.error?.message}
                size="medium"
                label="Title"
              />
            )}
          />
          <Controller
            control={methods.control}
            name="message"
            render={({ field: { ref: _, ...field }, fieldState }) => (
              <SmallWhiteTextField
                {...field}
                error={fieldState.error?.message}
                size="medium"
                multiline
                label="Message"
              />
            )}
          />
        </Stack>
      </FormProvider>
      <DialogActions sx={{ py: 3, px: 0 }}>
        <Button
          onClick={handleClose}
          variant="outlined"
          color="inherit"
          disabled={isPending}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={isPending}
        >
          {submitButtonContent}
        </Button>
      </DialogActions>
    </Stack>
  );
};
