import type { ProjectStyleEnum } from "@ll-web/config/featureFlags/consts";
import { AccountType, type User } from "@ll-web/features/auth/types";
import {
  BrandRoleEnum,
  type BrandUserWithProfile,
} from "@ll-web/features/brands/types";
import { ProjectSpacePages } from "@ll-web/features/projectSpace/enums";
import { makeProjectSpacePagePath } from "@ll-web/features/projectSpace/utils/navigation";
import { makeWizardPath } from "@ll-web/features/projectWizard/utils/navigation";
import { defined } from "@ll-web/utils/types/types";

import { ReviewNotificationType } from "./types";

const creativeDeckEditDefaults: {
  title: (projectName: string) => string;
  message: (props: {
    projectName: string;
    projectStyle: ProjectStyleEnum;
    defaultReceiverFirstName: string;
    projectId: string;
  }) => string;
} = {
  title: (projectName) =>
    `The Creative Deck for ${projectName} has been updated`,
  message: ({ defaultReceiverFirstName, projectId }) =>
    `Hi ${defaultReceiverFirstName},

We’ve updated the Creative Deck to align with the project vision we previously discussed.
Please take a moment to review it and ensure everything meets your expectations.

You can access the Creative Deck here:
${location.origin}${makeProjectSpacePagePath(projectId, ProjectSpacePages.CreativeDeck)}`,
};

const notifyReviewEmailDefaults: Record<
  ReviewNotificationType,
  {
    title: (projectName: string) => string;
    message: (props: {
      projectName: string;
      projectStyle: ProjectStyleEnum;
      defaultReceiverFirstName: string;
      projectId: string;
    }) => string;
  }
> = {
  [ReviewNotificationType.CreativeBriefInternal]: {
    title: (projectName) =>
      `Your project, ${projectName}, is ready for Creative Brief input`,
    message: ({
      defaultReceiverFirstName,
      projectName,
      projectStyle,
      projectId,
    }) =>
      `Hi ${defaultReceiverFirstName},

The ${projectStyle} project, ${projectName} has been created and is ready for your input.
Please take a moment to go in and generate the details needed within the Creative Brief to keep the process moving forward.

You can access the Creative Brief here: ${location.origin}${makeWizardPath(projectId)}

Feel free to reach out to your Account Executive if you have any questions or need further information.`,
  },
  [ReviewNotificationType.WizardGenerateInternal]: {
    title: (projectName) =>
      `Your project, ${projectName}, is ready for your review`,
    message: ({ defaultReceiverFirstName, projectName, projectId }) =>
      `Hi ${defaultReceiverFirstName},

Great news! Your project, ${projectName}, is ready for your review. We’ve submitted it for your feedback and are eager to hear your thoughts to ensure everything meets your expectations.

You can review the Creative Brief here: ${location.origin}${makeWizardPath(projectId)}

If you have any questions or additional feedback, feel free to reach out. We’re excited to move forward with the next steps!`,
  },
  [ReviewNotificationType.WizardGenerateExternal]: {
    title: (projectName) => `${projectName} is ready for your review`,
    message: ({ defaultReceiverFirstName, projectId }) =>
      `Hi ${defaultReceiverFirstName},

I have reviewed and submitted my project’s Creative Brief for your further review and processing.
Please let me know if you need anything else from my end.

You can also access the Creative Deck and Call Sheet here:
${location.origin}${makeProjectSpacePagePath(projectId, ProjectSpacePages.CreativeDeck)}
${location.origin}${makeProjectSpacePagePath(projectId, ProjectSpacePages.CallSheets)}`,
  },
  [ReviewNotificationType.CreativeDeckEditInternal]: creativeDeckEditDefaults,
  [ReviewNotificationType.CreativeDeckEditExternal]: creativeDeckEditDefaults,
};

export const getDefaultReceiverForNotificationType = {
  [ReviewNotificationType.CreativeBriefInternal]: (
    users: BrandUserWithProfile[],
  ) => {
    return [
      users.find((user) => user.user?.accountType === AccountType.Producer),
    ].filter(defined);
  },
  [ReviewNotificationType.WizardGenerateInternal]: (
    users: BrandUserWithProfile[],
  ) => {
    return [
      users.find((user) => user.role === BrandRoleEnum.Owner),
      users.find(
        (user) => user.user?.accountType === AccountType.AccountExecutive,
      ),
    ].filter(defined);
  },
  [ReviewNotificationType.WizardGenerateExternal]: (
    users: BrandUserWithProfile[],
  ) => {
    return [
      users.find((user) => user.user?.accountType === AccountType.Producer),
    ].filter(defined);
  },
  [ReviewNotificationType.CreativeDeckEditInternal]: (
    users: BrandUserWithProfile[],
  ) => {
    return [users.find((user) => user.role === BrandRoleEnum.Owner)].filter(
      defined,
    );
  },
  [ReviewNotificationType.CreativeDeckEditExternal]: (
    users: BrandUserWithProfile[],
  ) => {
    return [
      users.find((user) => user.user?.accountType === AccountType.Producer),
    ].filter(defined);
  },
};

export const getNotifyFormDefaultValues = ({
  projectName,
  notificationType,
  projectStyle,
  defaultReceivers,
  defaultReceiversData,
  projectId,
}: {
  projectName: string;
  notificationType: ReviewNotificationType;
  defaultReceivers: string[];
  defaultReceiversData: User[];
  projectStyle: ProjectStyleEnum;
  projectId: string;
}) => {
  return {
    people: defaultReceivers ?? [],
    ...notifyReviewEmailDefaults[notificationType],
    title: notifyReviewEmailDefaults[notificationType].title(projectName),
    message: notifyReviewEmailDefaults[notificationType].message({
      projectName,
      defaultReceiverFirstName:
        defaultReceiversData.find(
          (userData) => userData._id === defaultReceivers[0],
        )?.firstName ?? "",
      projectStyle,
      projectId,
    }),
  };
};
