import { useCallback, useMemo, useState } from "react";

import { activityTracker } from "@ll-web/core/analytics/activityTracker";
import { mapProjectToAnalytics } from "@ll-web/core/analytics/eventUtils";
import type { TypedTrackingEvent } from "@ll-web/core/analytics/types";
import { useActiveUser } from "@ll-web/features/auth/hooks/useActiveUser";
import { useActiveProject } from "@ll-web/features/projectWizard/hooks/useActiveProject";

import { notifyReviewAnalyticsEventsMap } from "./consts";
import type { NotifyForReviewFormValues } from "./notify-for-review.schema";
import {
  NotifyForReviewModal,
  type NotifyForReviewModalProps,
} from "./NotifyForReviewModal";

type UseNotifyForReviewDialogArgs = {
  onSubmit: (payload: NotifyForReviewFormValues) => Promise<void>;
  onCancel?: VoidFunction;
  dialogProps: Omit<
    NotifyForReviewModalProps,
    "onSubmitForReview" | "open" | "close"
  >;
};

export const useNotifyForReviewDialog = ({
  onSubmit,
  onCancel,
  dialogProps,
}: UseNotifyForReviewDialogArgs) => {
  const { activeUser } = useActiveUser();
  const { activeProject } = useActiveProject();
  const [isNotifyModalOpen, setIsNotifyModalOpen] = useState(false);

  const openNotifyModal = useCallback(() => {
    setIsNotifyModalOpen(true);
  }, []);

  const handleConfirm = useCallback(
    async (payload: NotifyForReviewFormValues) => {
      const events =
        notifyReviewAnalyticsEventsMap[dialogProps.notificationType];

      if (events) {
        activityTracker.log({
          type: events.submitting,
          metadata: {
            ...mapProjectToAnalytics(activeProject),
          },
        } as TypedTrackingEvent);
      }

      await onSubmit(payload);
      setIsNotifyModalOpen(false);
    },
    [onSubmit, dialogProps.notificationType, activeProject],
  );

  const handleCancel = useCallback(() => {
    const events = notifyReviewAnalyticsEventsMap[dialogProps.notificationType];

    if (events) {
      activityTracker.log({
        type: events.cancel,
        metadata: {
          senderRole: activeUser.accountType,
          ...mapProjectToAnalytics(activeProject),
        },
      } as TypedTrackingEvent);
    }

    setIsNotifyModalOpen(false);
    onCancel?.();
  }, [onCancel, dialogProps.notificationType, activeProject, activeUser]);

  const notifyForReviewDialogNode = useMemo(() => {
    return (
      <NotifyForReviewModal
        onSubmitForReview={handleConfirm}
        open={isNotifyModalOpen}
        close={handleCancel}
        {...dialogProps}
      />
    );
  }, [handleConfirm, isNotifyModalOpen, handleCancel, dialogProps]);

  return useMemo(
    () => ({
      openNotifyModal,
      notifyForReviewDialogNode,
      isNotifyModalOpen,
    }),
    [openNotifyModal, notifyForReviewDialogNode, isNotifyModalOpen],
  );
};
