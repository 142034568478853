import { type ReactNode } from "react";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  type DialogProps,
} from "@mui/material";

import { Spinner } from "@ll-web/components/Spinner/Spinner";
import {
  useGetBrandById,
  useGetBrandByProjectId,
} from "@ll-web/features/brands/async/useBrandsQueries";
import { useActiveProject } from "@ll-web/features/projectWizard/hooks/useActiveProject";
import { UsersQueries } from "@ll-web/features/users/async/useUsersQueries";
import { useBatchQuery } from "@ll-web/utils/hooks/useBatchQueries";
import { defined } from "@ll-web/utils/types/types";

import { notifyReviewModalTitleMap } from "./consts";
import { getDefaultReceiverForNotificationType } from "./defaults";
import type { NotifyForReviewFormValues } from "./notify-for-review.schema";
import { NotifyModalForm } from "./NotifyModalForm";
import type { ReviewNotificationType } from "./types";

export type NotifyForReviewModalProps = DialogProps & {
  notificationType: ReviewNotificationType;
  close: () => void;
  onSubmitForReview: (payload: NotifyForReviewFormValues) => Promise<void>;
  confirmationContent?: ReactNode;
  submitButtonContent?: string;
};

export const NotifyForReviewModal = ({
  confirmationContent = "You're about to submit for review. Please confirm that all necessary edits have been made.",
  submitButtonContent = "Submit for Review",
  notificationType,
  close,
  onSubmitForReview,
  ...dialogProps
}: NotifyForReviewModalProps) => {
  const { activeProject } = useActiveProject();

  const brandByProjectQuery = useGetBrandByProjectId({
    projectId: activeProject.id,
  });

  const brandId = brandByProjectQuery.data?._id;
  const brandByIdQuery = useGetBrandById(
    {
      brandId: brandId!,
    },
    { enabled: !!brandId },
  );

  const projectTeam =
    brandByIdQuery?.data?.team?.filter((teamMember) => !!teamMember.user) ?? [];

  const defaultReceivers = getDefaultReceiverForNotificationType[
    notificationType
  ](projectTeam)
    .map((receiverProfile) => receiverProfile.user?._id)
    .filter(defined);

  const defaultUsersQuery = useBatchQuery(
    defaultReceivers.map((userId) => UsersQueries.getById({ userId })),
  );

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      {...dialogProps}
      PaperProps={{ sx: { minHeight: 600 } }}
    >
      <DialogTitle sx={{ py: 2, px: 3 }}>
        {notifyReviewModalTitleMap[notificationType]}
      </DialogTitle>
      <DialogContent
        sx={{
          py: 0,
          display: "flex",
        }}
      >
        {brandByIdQuery.isFetching || defaultUsersQuery.isFetching ? (
          <Stack
            justifyContent="center"
            alignItems="center"
            alignSelf="center"
            height="100%"
            width="100%"
          >
            <Spinner size={64} />
          </Stack>
        ) : (
          <NotifyModalForm
            defaultReceivers={defaultReceivers}
            defaultReceiversData={defaultUsersQuery.data?.filter(defined) ?? []}
            notificationType={notificationType}
            onSubmitForReview={onSubmitForReview}
            confirmationContent={confirmationContent}
            submitButtonContent={submitButtonContent}
            onClose={close}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};
